import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form'


class Text extends Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }

    render() {
        return (
                <div className="position-relative form-group">
                    <input className={this.props.error ? 'form-control required' : 'form-control'} type={this.props.type} onChange={this.props.onChange} value={this.props.value} placeholder={this.props.placeholder} />
                </div>
        );
    }
}


class Textarea extends Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }

    render() {
        return (
            <div>
                <textarea className={this.props.error ? 'form-control required' : 'form-control'} onChange={this.props.onChange} value={this.props.value} placeholder={this.props.placeholder}></textarea>
            </div>
        );
    }
}


export const renderTextField = ({
    input,
    placeholder,
    label,
    type,
    meta: { touched, error, warning },
    requiredField
}) => (
        <Text
            placeholder={placeholder}
            label={label}
            type={type}
            errorText={touched && error}
            error={touched && error}
            requiredField={requiredField}
            {...input}
        />
    )

export const renderTextareaField = ({
    input,
    placeholder,
    label,
    meta: { touched, error },

}) => (
        <Textarea
            placeholder={placeholder}
            label={label}
            errorText={touched && error}
            error={touched && error}
            {...input}
        />
    )



export const email = value =>
    value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value) ?
        'Unesite E-mail adresu u pravilnom formatu' : undefined;


