import React, { Component } from 'react'
import Isvg from 'react-inlinesvg';
import Navigacija from '../containers/navbar';
import News from '../components/news';
import Footer from '../containers/footer';
import { Link } from 'react-router-dom';
import newsDetailPicture from '../images/newsDetail.png';
import AnimtedButton from '../components/animatedButton';
import PageWithLayout from '../containers/page';
import moment from 'moment';

import {
    Container,
    Row,
    Col,
    NavItem, NavLink,
    Input, FormGroup, Table, Form, Label
} from 'reactstrap'


class ShipmentTracking extends Component {
    constructor(props) {
        super(props);
        this.track = this.track.bind(this);

        this.state = {

        }
    }

    componentDidMount(){
        if (this.props[0].match.params.code){
            this.setState({
                code: this.props[0].match.params.code
            }, () => this.track())
        }
    }

    track() {
        fetch('https://gateway.euroexpress.ba:8083/api/v1/pub/track?trackingNumber=' + this.state.code).then((res) => res.json()).then((result) => {
            console.log(result)
            if (result.error) {
                this.setState({
                    _error: true,
                    data: null
                })
            } else {
                this.setState({
                    data: result,
                    _error: null
                })
            }
        })
    }

    render() {
        const buttonText = "PRONAĐITE POŠILJKU";
        console.log(this.props)
        return (
            <div>
                <Container>
                    <Row noGutters>
                        <div className="bredkramb">
                            <h6>Praćenje pošiljke</h6>
                            <div className="links">
                                <NavItem>
                                    <Link to="/">Početna</Link>
                                </NavItem>
                                <NavItem>
                                    <Link to="/">Praćenje pošiljke</Link>
                                </NavItem>
                            </div>
                        </div>
                    </Row>
                </Container>
                <Container className="universalPageWrapper">
                    <Row>
                        <Col xl="12" lg="12" md="12" xs="12">
                                <p>Unesite kod vaše pošiljke (nalazi se na tovarnom listu) koju želite da pratite. Pošiljke možete pratiti 24 časa dnevno. Svaka pošiljka se prati posebno.</p>
                            <Row>
                                <Col xl="6" lg="6" md="6" xs="12">
                                    <Input type="search" placeholder="Unesite kod vaše pošiljke" value={this.state.code} onChange={(e) => this.setState({ code: e.target.value })} />
                                </Col>
                                <Col xl="3" lg="3" md="4" xs="7">
                                    <AnimtedButton buttonText={buttonText} handleClick={this.track} />
                                </Col>

                            </Row>

                        
                {
                    this.state._error ?
                    <p style={{marginTop: '10px', color: '#DA212E'}}>Greška, pošiljka nije pronađena!</p>
                    :

                    null
                }
                            </Col>
                    </Row>
                    {this.state.data ?
                        <>

                            <Row>
                                <Col xl="4" lg="4" md="4" xs="12">
                                    <div className="shipmentCode">
                                        <h6>Pošiljka broj: {this.state.data.sifra}</h6>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col xl="12">
                                    <Table responsive>
                                        <thead>
                                            <tr>
                                                <th>Datum i vrijeme promjene</th>
                                                <th>Mjesto</th>
                                                <th>Opis događaja</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                this.state.data && this.state.data.events && this.state.data.events.map((item, idx) => {
                                                    return (
                                                        <tr>
                                                            <td>{moment(item.datum).format('DD MMM YYYY, HH:mm')}</td>
                                                            <td>{item.centar}</td>
                                                            <td>{item.opis}</td>
                                                        </tr>

                                                    )
                                                })
                                            }
                                        </tbody>
                                    </Table>
                                </Col>
                                <Col xl="6" lg="6" md="6" xs="12" className="table2">
                                    <Table responsive>
                                        <thead>
                                            <tr>
                                                <th>Podaci o pošiljci</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>Datum slanja pošiljke:</td>
                                                <td>{moment(this.state.data.datumSlanja).format('DD MMM YYYY, HH:mm')}</td>
                                            </tr>
                                            {/* <tr>
                                                <td>Vrsta pošiljke:</td>
                                                <td>{this.state.data.opisPosiljke}</td>
                                            </tr> */}
                                            <tr>
                                                <td>Masa (kg):</td>
                                                <td>{this.state.data.tezina}</td>
                                            </tr>
                                            <tr>
                                                <td>Broj paketa:</td>
                                                <td>{this.state.data.brojPaketa}</td>
                                            </tr>
                                            <tr>
                                                <td>Pošiljku preuzeo:</td>
                                                <td>{this.state.data.posiljkuPreuzeo ? this.state.data.posiljkuPreuzeo : '-'}</td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </Col>
                            </Row>
                            <Row>
                                <Col xl="12">
                                    <p>Ovo je informacija o trenutnom statusu i lokaciji pošiljke za koju ste pokrenuli praćenje. Svaka pošiljka se prati posebno.</p>
                                </Col>
                            </Row>
                        </>
                        :
                        null
                    }

                </Container>
            </div>
        )
    }
}

export default PageWithLayout(ShipmentTracking)
