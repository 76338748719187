import React, { Component } from 'react'
import serviceSvg1 from '../images/svg/service-svg1.svg';
import serviceSvg2 from '../images/svg/service-svg2.svg';
import serviceSvg3 from '../images/svg/service-svg3.svg';
import serviceSvg4 from '../images/svg/service-svg4.svg';
import strelicaLijevo from '../images/svg/strelicaLijevo.svg';
import Isvg from 'react-inlinesvg';
import { Link } from 'react-router-dom';

import {
    Container,
    Row,
    Col
} from 'reactstrap';
export class servicesSection extends Component {
    render() {
        return (
            <Container className="servicesSection">
                <Row>
                    <div className="multipleTitle">
                        <h1>SERVISI</h1>
                        <div className="box">
                            <h3 className="universalh3">Iskoristite <span>prednosti</span> naših online servisa</h3>
                        </div>
                    </div>
                </Row>
                <Row className="serviceBoxRow">
                    {
                        this.props.items.map((item, idx) => {
                            if (idx != 2)
                            return (
                                <Col xl="4" lg="4" md="6" xs="6" className="servicesBoxWrapper" key={idx}>
                                <div className="servicesBox">
                                    <Link to={item.link ? item.link : "/"}>
                                        <Isvg src={item.image} />
                                        <h3>{item.name}</h3>
                                        <p className="universalParagraph">{item.content}</p>
                                        <button><Isvg src={strelicaLijevo} /></button>
                                        <div className="overlay"></div>
        
                                    </Link>
                                </div>
                            </Col>
        
                            )
                        })
                    }
                </Row>
            </Container>
        )
    }
}

export default servicesSection
