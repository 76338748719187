import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form'
import Isvg from 'react-inlinesvg';
import AnimtedButton from '../components/animatedButton';
import showResults from '../showResults.js';

import {
    Container, Row, Col, Button, FormGroup,
    Input, NavItem
} from 'reactstrap';

import { renderTextField, renderTextareaField, email } from '../components/fields/fields.js'


const required = value => value ? undefined : "Required"

const form = (props) => {
    const { handleSubmit, pristine, reset, submitting } = props;

    const buttonText = "POŠALJI";
    return (
        <form onSubmit={handleSubmit}>
            <Row>
                <Col xl="6" lg="6" md="6" xs="12">
                    <Field validate={[required]} component={renderTextField} type="text" name="firstName" placeholder="Ime"></Field>
                </Col>
                <Col xl="6" lg="6" md="6" xs="12">
                    <Field validate={[required]} component={renderTextField} type="text" name="lastName" placeholder="Prezime"></Field>
                </Col>
                <Col xl="6" lg="6" md="6" xs="12">
                    <Field validate={[required, email]} component={renderTextField} type="email" name="email" placeholder="E-mail"></Field>
                </Col>
                <Col xl="6" lg="6" md="6" xs="12">
                    <Field validate={[required]} component={renderTextField} type="text" name="phone" placeholder="Telefon"></Field>
                </Col>
                <Col xl="12" lg="12" md="12" xs="12">
                    <Field validate={[required]} component={renderTextField} type="text" name="subject" placeholder="Naslov"></Field>
                </Col>
                <Col xl="12" lg="12" md="12" xs="12">
                    <Field validate={[required]} component={renderTextareaField} type="textarea" name="message" placeholder="Poruka"></Field>
                </Col>
                <Col xl="3" lg="3" md="4" xs="5">
                    <AnimtedButton buttonText={buttonText} />
                </Col>
            </Row>
        </form>
    )
}
export default reduxForm({
    form: 'form'  // a unique identifier for this form
})(form)
