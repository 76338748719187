import React, { Component } from 'react'
import Isvg from 'react-inlinesvg';
import { Link } from 'react-router-dom';
import StrelicaDole from '../images/svg/strelicaDole.svg';
import footerLogo from '../images/svg/footerLogo.svg';
import footerBG from '../images/footerBG.png';
import footerFacebook from '../images/svg/footerFacebook.svg';
import footerInstagram from '../images/svg/footerInstagram.svg';
import footerLinkedIn from '../images/svg/footerLinkedIn.svg';
import googlePlay from '../images/google-play-badge.png';
import ios from '../images/ios.png';

import {
    Container,
    Row,
    Col,
    NavItem
} from 'reactstrap';

class Footer extends Component {
    render() {
        return (
            <div className="footer">
                <Container>
                    <Row>
                        <Col xl="9" lg="9" className="offset-xl-3 offset-lg-3">
                            <div className="footerNavbar">
                                <NavItem>
                                    <Link to="/">POČETNA</Link>
                                </NavItem>
                                <NavItem>
                                    <Link to="/o-nama/istorijat">O NAMA</Link>
                                </NavItem>
                                <NavItem>
                                    <Link to="/usluge/nase-osnovne-usluge%20/domaci-transport">NAŠE USLUGE</Link>
                                </NavItem>
                                <NavItem>
                                    <Link to="/novosti/aktuelna-dešavanja">NOVOSTI</Link>
                                </NavItem>
                                <NavItem>
                                    <Link to="/zaposlenje/stranica/otvorena-radna-mjesta">ZAPOSLENJE</Link>
                                </NavItem>
                                <NavItem>
                                    <Link to="/stranica/kontakti">KONTAKT</Link>
                                </NavItem>
                            </div>

                        </Col>
                    </Row>
                    <Row className="suggestions">
                        <Col xl="3" lg="3" md="3" xs="6">
                            <Isvg src={footerLogo} className="footerLogo" />
                        </Col>
                        <Col xl="6" lg="6" md="6" xs="12" className="offset-xl-3 offset-lg-3 offset-md-3">
                            <h6>Pohvale, primjedbe, žalbe</h6>
                            <p className="universalParagraph">Ukoliko imate, pohvalu, primjedbu, reklamaciju  ili žalbu na naše usluge,
    molimo Vas da nam istu pošaljete na <span>sugestije@euroexpress.ba.</span>
                                <br />Odgovor ćete dobiti u najkraćem mogućem roku.</p>

                        </Col>
                    </Row>
                    <Row className="socijalneMreze">
                        <Col xl="3" lg="6">
                            <p className="social">Pratite nas na društvenim mrežama</p>
                            <a href="https://www.facebook.com/euroexpress.ba/" target="_blank"><Isvg src={footerFacebook} /></a>
                            <a href="https://www.instagram.com/euroexpressbrzaposta/" target="_blank"><Isvg src={footerInstagram} /></a>
                            <a href="https://www.linkedin.com/company/euroexpress-brza-posta" target="_blank"><Isvg src={footerLinkedIn} /></a>
                        </Col>
                        <Col xl="9" lg="6" className="app-icons">
                        <a href="https://play.google.com/store/apps/details?id=com.euroexpress.android" target="_blank" className="google-play">
                                <img src={googlePlay} />
                            </a>
                            <a href="https://apps.apple.com/us/app/euroexpress-brza-po%C5%A1ta/id1484650377?ign-mpt=uo%3D2" target="_blank" className="google-play">
                                <img src={ios} />
                            </a>


                        </Col>
                    </Row>
                    <Row className="copyright">
                        <Col xl="5" lg="5" md="6" xs="7">
                            <p>Copyright © EuroExpress d.o.o. - 2018. Sva prava zadržana.</p>
                            <p>Created by <a href="https://www.novamedia.agency/" target="_blank">NOVA media</a></p>
                        </Col>
                        <Col xl="2" lg="2" md="3" xs="5">
                            <Link className="link-no-decoration" to='/stranica/call-centar'>  <div className="info">
                                <h6>INFO BROJ</h6>
                                <h1>1331</h1>
                            </div></Link>
                        </Col>
                    </Row>
                </Container>
                <img src={footerBG} className="footerBG" />
            </div>
        )
    }
}

export default Footer;
