import React, { Component } from 'react'
import News from '../components/news';
import { Link } from 'react-router-dom';
import { NavLink as RRNavLink } from 'react-router-dom';
import PageWithLayout from '../containers/page';
import Form from '../components/forms/sendPackage';
import {
    Container,
    Row,
    Col,
    NavItem, NavLink
} from 'reactstrap'
import moment from 'moment';

const token = 'eyJraWQiOiI4OGI4NWQzYS04NDJhLTQ5MzgtOWRhYS1hMjkyNGRkY2JhYzEiLCJ0eXAiOiJKV1QiLCJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJ3ZWJzaXRlQGxvY2FsaG9zdC5sb2NhbGhvc3QiLCJpc3MiOiJFdXJvRXhwcmVzcyBkLm8ubyIsImVtYWlsIjoid2Vic2l0ZUBsb2NhbGhvc3QubG9jYWxob3N0In0.d7JEF_lfxvsPwwvCtgjfr2KRtdxb62JpEOaZ3-n-LPdxpyVg1mJnysrNhqQT3-3pdvwxdg618RxBEDdFk0M20w';

class sendPackage extends Component {
    constructor(props) {
        super(props);
        this.send = this.send.bind(this);

        this.state = {
        }
    }

    componentDidMount() {



    }

    send(formData) {
        if (this.state._sending){
            return;
        }

        this.setState({
            _sending: true
        }, () => {
            let data = JSON.parse(JSON.stringify(formData));
            const { timeLimited, timeLimitedFrom, timeLimitedTo } = data.shipmentMetadata;
            const random = Math.floor(Math.random() * 10000000);
            const referentniBroj = `RB_${random}`;
            let payload = {...{referentniBroj}, ...data.shipmentMetadata}
            if (timeLimited) {
                payload.vrijemeDostaveOd = timeLimitedFrom;
                payload.vrijemeDostaveDo = timeLimitedTo;
                delete payload.timeLimited;
                delete payload.timeLimitedFrom;
                delete payload.timeLimitedTo;
            }
            if(data.senderInformation && Object.entries(data.senderInformation).length > 0) {
                const { name, address, postalCode, phone } = data.senderInformation;
                payload.posiljalacNaziv  = name;
                payload.posiljalacAdresa  = address;
                payload.posiljalacPtt  = postalCode;
                payload.posiljalacTelefon  = phone;
            }
            if(data.receiverInformation && Object.entries(data.receiverInformation).length > 0) {
                const { name, address, postalCode, phone } = data.receiverInformation;
                payload.primalacNaziv = name;
                payload.primalacAdresa = address;
                payload.primalacPtt = postalCode;
                payload.primalacTelefon = phone;

            }
            payload.obveznikPlacanja = data.paymentType === 'posiljalac' ? 0 : data.paymentType === 'primalac' ? 1 : null;

            fetch('https://gateway.euroexpress.ba/v1/package/announce-other/single', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'API-KEY': 'QxwNKo3eAMbmqdavYnlqGp1d'

                },
                body: JSON.stringify(payload)
            }).then((res) => { if (res.status == 200) { return {} } else { return { error: true } } }).then((result) => {
                console.log('done')
                if (result.error) {
                    this.setState({
                        _error: true,
                        _sending: false
                    })

                } else {
                    this.setState({
                        _done: true,
                        _sending: false
                    })
                }
            }).catch((e) => {
                this.setState({
                    _sending: false
                })
            });

        })

    }


    render() {



        return (
            <div>
                <Container>
                    <Row noGutters>
                        <div className="bredkramb">
                            <h6>Slanje pošiljke</h6>
                            <div className="links">

                                <NavItem>
                                    <Link to="/">Početna</Link>
                                </NavItem>
                                <NavItem>
                                    <Link to="/slanje-posiljke">Slanje pošiljke</Link>
                                </NavItem>
                            </div>
                        </div>
                    </Row>
                </Container>
                <Container className="universalPageWrapper">
                    <Row>
                        <Col xl="12" lg="12" md="12" xs="12">
                            <Container>
                                <Row>

                                    <Form onSubmit={this.send} initialValues={{shipmentMetadata: {vrstaPosiljkeOznaka: 'Paket'}, senderInformation: {}, receiverInformation: {}}}></Form>
                                    {this.state._done ?
                                        <Col lg="12">
                                            <div class="alert alert-info mt-4" role="alert">Uspješno najavljeno preuzimanje pošiljke</div>
                                        </Col>
                                        :
                                        null
                                    }
                                    {this.state._error ?
                                        <Col lg="12">
                                            <div class="alert alert-info mt-4" role="alert">Greška!</div>
                                        </Col>
                                        :
                                        null
                                    }

                                </Row>
                            </Container>
                        </Col>
                    </Row>
                </Container>

            </div>
        )
    }
}

export default PageWithLayout(sendPackage)
