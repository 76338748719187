import React, { Component } from 'react'
import picture1 from '../images/carousel1.png';
import picture2 from '../images/carousel2.png';
import picture3 from '../images/carousel3.png';
import AnimtedButton from '../components/animatedButton';
import { Link } from 'react-router-dom';
import SkrolDole from '../images/svg/skrolDole.svg';
import Isvg from 'react-inlinesvg';
import {
    Container,
    Row,
    Col,
    Carousel,
    CarouselItem,
    CarouselControl,
    CarouselIndicators,
    CarouselCaption
} from 'reactstrap';
import { API_ENDPOINT } from '../constants';
var striptags = require('striptags');

const items = [
    {
        src: picture1,
        title: 'DOLASKOM U NAŠE POSLOVNICE',
        title2: 'ŠTEDITE VAŠ NOVAC',
        description: 'Iskoristite naše pogodnosti u svim paket šopovina širom BiH.',
        btnLink: 'asd',
        pictureNumber: '01'
    },
    {
        src: picture2,
        title: 'NOVA LOKACIJA DISTRIBUTIVNOG',
        title2: 'CENTRA TUZLA',
        description: 'Obavještavamo korisnike usluga EuroExpress brze pošte da je u cilju pružanja još bolj i kvalitetnije usluge distributivni centar u Tuzli preseljen na novu lokaciju.',
        btnLink: 'asd2',
        pictureNumber: '02'

    },
    {
        src: picture3,
        title: 'STOPA DGL-a',
        title2: 'I LICENCU',
        description: 'Dodatak za gorivo i licencu za mjesec maj iznosi 15%',
        btnLink: 'asd3',
        pictureNumber: '03'

    }
];


class carousel extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeIndex: 0,
            slides: [],
            lastNewsItem: {}
        };

        this.next = this.next.bind(this);
        this.previous = this.previous.bind(this);
        this.goToIndex = this.goToIndex.bind(this);
        this.onExiting = this.onExiting.bind(this);
        this.onExited = this.onExited.bind(this);
    }
    onExiting() {
        this.animating = true;
    }

    onExited() {
        this.animating = false;
    }

    next() {
        if (this.animating) return;
        const nextIndex = this.state.activeIndex === this.state.slides.length - 1 ? 0 : this.state.activeIndex + 1;
        this.setState({ activeIndex: nextIndex, sledeci: true }, () => {
            setTimeout(() => {
                this.setState({ sledeci: null })
            }, 600);
        });
    }

    previous() {
        if (this.animating) return;
        const nextIndex = this.state.activeIndex === 0 ? this.state.slides.length - 1 : this.state.activeIndex - 1;
        this.setState({ activeIndex: nextIndex, prethodni: true }, () => {
            setTimeout(() => {
                this.setState({ prethodni: null })
            }, 600);
        });

    }

    goToIndex(newIndex) {
        if (this.animating) return;
        this.setState({ activeIndex: newIndex });
    }

    componentDidMount() {
        fetch(API_ENDPOINT + '/slides', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            }
        }).then((res) => res.json()).then((result) => {

            fetch(API_ENDPOINT + '/lastNews', {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                }
            }).then((res) => res.json()).then((newsItem) => {

                /*result.push({
                    buttonText: 'OPŠIRNIJE',
                    buttonLink: '/novosti/'+ (newsItem[0].name && newsItem[0].name.generateAlias()) +'/'+newsItem[0]._id,
                    name: newsItem[0].name,
                    content: striptags(newsItem[0].content).length > 140 ? striptags(newsItem[0].content).substr(0, 140) + '...' : striptags(newsItem[0].content),
                    image: picture2
                })*/

                this.setState({
                    slides: result
                })

                console.log(result);
            })


            console.log(result);
        })




    }


    render() {
        const buttonText = "DETALJNIJE";
        const { activeIndex } = this.state;

        const slides = this.state.slides.map((item, idx) => {
            return (
                <CarouselItem
                    onExiting={this.onExiting}
                    onExited={this.onExited}
                    key={item.src}
                >
                    <Row noGutters className={this.state.prethodni ? "animated fadeOut" : null}>
                        <Col xl="5" lg="6" md="6" xs="5">
                            <div className={this.state.sledeci ? "carouselText animated fadeOut" : this.state.prethodni ? "carouselText animated fadeOut" : "carouselText animated fadeIn"}>
                                <h1 className="bigTitle">{`0${idx + 1}`}</h1>
                                <h1>{item.name}</h1>
                                <h1 className="redTitle">{item.redTitle}</h1>
                                <p dangerouslySetInnerHTML={{ __html: item.content }}></p>
                                <a target={idx == 0 ? '_blank' : null} href={item.buttonLink ? item.buttonLink : '/'}><AnimtedButton buttonText={item.buttonText} /></a>
                            </div>
                        </Col>
                        <Col xl="7" lg="6" md="6" xs="7">
                            <img src={item.image} alt={item.name} className={this.state.sledeci ? "img-fluid animated fadeOut" : this.state.prethodni ? "img-fluid animated fadeOut" : "img-fluid animated fadeIn"} />
                        </Col>
                    </Row>

                </CarouselItem >
            );
        });
        return (
            <Container>
                <Carousel
                    activeIndex={activeIndex}
                    next={this.next}
                    previous={this.previous}
                >
                    <CarouselIndicators items={this.state.slides} activeIndex={activeIndex} onClickHandler={this.goToIndex} />
                    {slides}

                    <div className="scrollDiv">
                        <button onClick={this.props.onScroll}>
                            <Isvg src={SkrolDole} />
                            <p>SKROLUJ DOLE</p>
                        </button>
                    </div>
                </Carousel>
            </Container >
        )
    }
}

export default carousel
