import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Navigacija from './navbar';
import Footer from './footer';




export const DefaultLayout = (Wrapped) => (props) => {
    return (

        <div>
            <Navigacija {...props} home={props[0].location.pathname == "/" ? true : false} />
            <Wrapped {...props} />
            <Footer />

        </div>
    );
};

export default DefaultLayout;