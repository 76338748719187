import React from 'react';
import mapIcon from '../images/mapIcon1.png';
import slika from '../images/news4.png';
import {
  Container,
  Row,
  Col,
} from 'reactstrap';

const locations = [
  {
    name: 'Distributivni centar i Paket Shop Laktaši (Jakupovci)',
    address: 'Omladinska 66b, Jakupovci - Laktaši',
    location: [44.8885236, 17.2936099],
    workTimeWeek: '8.00 h - 20.00 h',
    workTimeSatudaryD: '8.00 h - 14.00 h',
    workTimeSatudaryP: 'Zatvoreno',
    workTimeSunday: 'Zatvoreno'
  },
  /*
  {
    name: 'Poslovnica Mostar',
    address: 'Kneza Branimira 12',
    location: [43.3402592, 17.7967773],
    workTimeWeek: '8.00 h - 16.30 h',
    workTimeSatudary: 'Zatvoreno',
    workTimeSunday: 'Zatvoreno'
  },
  */
  {
    name: 'Paket Shop Sarajevo',
    address: 'Derviša Numića do br. 6',
    location: [43.849199, 18.3960655],
    workTimeWeek: '9.00 h - 17.00 h',
    workTimeSatudary: 'Zatvoreno',
    workTimeSunday: 'Zatvoreno'
  },
  // {
  //   name: 'Paket Shop Srebrenik',
  //   address: '21. srebreničke brigade',
  //   location: [44.7058969, 18.4915821],
  //   workTimeWeek: '9.30 h - 17.30 h',
  //   workTimeSatudary: 'Zatvoreno',
  //   workTimeSunday: 'Zatvoreno'
  // },
  /*
  {
    name: 'Paket Shop Živinice',
    address: 'Druga ulica 64',
    location: [44.4466279,18.6504481],
    workTimeWeek: '9.30 h - 17.30 h',
    workTimeSatudary: 'Zatvoreno',
    workTimeSunday: 'Zatvoreno'
  },
  */
  {
    name: 'Paket Shop Tuzla',
    address: '15 maja bb - (RK Sjenjak)',
    location: [44.5337326, 18.6967406],
    workTimeWeek: '8.00 h - 18.00 h',
    workTimeSatudary: 'Zatvoreno',
    workTimeSunday: 'Zatvoreno'
  },
  {
    name: 'Paket Shop Bijeljina',
    address: 'Majevička 175',
    location: [44.7605027127338, 19.21332563501472],
    workTimeWeek: '9.00 h - 17.00 h',
    workTimeSatudary: 'Zatvoreno',
    workTimeSunday: 'Zatvoreno'
  },
  {
    name: 'Paket Shop Gračanica',
    address: 'Zlatnih ljiljana 22',
    location: [44.6917957, 18.3001417],
    workTimeWeek: '9.30 h - 17.30 h',
    workTimeSatudary: 'Zatvoreno',
    workTimeSunday: 'Zatvoreno'
  },

  {
    name: 'Distributivni centar i Paket Shop Banja Luka',
    address: 'Jovana Dučića 23a',
    //location: [44.7760925, 17.18368989999999],
    location: [44.77625517, 17.183677221],
    workTimeWeek: '8.00 h - 21.30 h',
    workTimeSatudary: '8.00 h - 14.00 h',
    deliveryTimeWeek: '7.30 h - 21.00 h',
    takingTimeWeek: '7.30 h - 21.30 h',
    workTimeSunday: 'Zatvoreno'
  },
  {
    name: 'Distributivni centar i Paket Shop Sarajevo',
    address: 'Kasindolskog bataljona 2b',
    location: [43.8172659, 18.358181100000024],
    workTimeWeekD: '8.00 h - 20.00 h',
    workTimeWeekP: '7.30 h - 20.00 h',
    workTimeSatudary: '8.00 h - 14.00 h',
    workTimeSunday: 'Zatvoreno'
  },
  {
    name: 'Distributivni centar Sarajevo',
    address: 'Kasindolskog bataljona 2a',
    location: [43.817966, 18.358830],
    workTimeWeek: '8.00 h - 20.00 h',
    workTimeSatudary: '8.00 h - 14.00 h',
    workTimeSunday: 'Zatvoreno'
  },
  {
    name: 'Distributivni centar Foča',
    address: 'Branka Radičevića bb',
    location: [43.50334,18.78155],
    workTimeWeek: '8.00 h - 18.00 h',
    workTimeSatudary: '8.00 h - 14.00 h',
    workTimeSunday: 'Zatvoreno'
  },
  {
    name: 'Distributivni centar Trebinje',
    address: 'Gučina bb',
    location: [42.701036, 18.352862],
    workTimeWeek: '8.00 h - 17.00 h',
    workTimeSatudary: '8.00 h - 14.00 h',
    workTimeSunday: 'Zatvoreno'
  },
  {
    name: 'Distributivni centar Mostar',
    address: 'Bišće polje bb',
    location: [43.31075250000004, 17.833229899999992],
    workTimeWeek: '8.00 h - 18.00 h',
    workTimeSatudary: '8.00 h - 14.00 h',
    workTimeSunday: 'Zatvoreno'
  },
  {
    name: 'Distributivni centar i Paket Shop Bijeljina',
    address: 'Stefana Dečanskog bb',
    location: [44.76838680000003, 19.167387500000018],
    workTimeWeekD: '8.00 h - 18.00 h',
    workTimeWeekP: '7.00 h - 18.30 h',
    workTimeSatudaryD: '8.00 h - 14.00 h',
    workTimeSatudaryP: 'Zatvoreno',
    workTimeSunday: 'Zatvoreno'
  },
  {
    name: 'Distributivni centar i Paket Shop Doboj',
    address: 'Dobojske brigade 1',
    location: [44.714497, 18.080738],
    workTimeWeekD: '8.00 h - 20.00 h',
    workTimeWeekP: '9.00 h - 20.00 h',
    workTimeSatudaryD: '8.00 h - 14.00 h',
    workTimeSatudaryP: '8.00 h - 14.00 h',
    workTimeSunday: 'Zatvoreno'
  },
  {
    name: 'Distributivni centar Brčko',
    address: 'Lončari bb',
    location: [44.9410719, 18.6793066],
    workTimeWeek: '8.00 h - 18.30 h',
    workTimeSatudary: '8.00 h - 14.00 h',
    workTimeSunday: 'Zatvoreno'
  },
  {
    name: 'Distributivni centar Bihać',
    address: 'Ivana Franje Jukića 60',
    location: [44.80778130000001, 15.838926399999991],
    workTimeWeek: '8.00 h - 18.00 h',
    workTimeSatudary: '8.00 h - 14.00 h',
    workTimeSunday: 'Zatvoreno'
  },
  {
    name: 'Distributivni centar i Paket Shop Zenica',
    address: 'Blatuša bb',
    location: [44.2141238, 17.9130785],
    workTimeWeek: '8.00 h - 19.30 h',
    workTimeSatudaryD: '8.00 h - 14.00 h',
    workTimeSatudaryP: '8.00 h - 14.00 h',
    workTimeSunday: 'Zatvoreno'
  },
  {
    name: 'Distributivni centar Tuzla i Paket Shop Lukavac',
    address: 'Donji Bistarac bb, 75300 Lukavac',
    location: [44.513274, 18.554068],
    workTimeWeekD: '8.00 h - 18.00 h',
    workTimeWeekP: '9.00 h - 20.00 h',
    workTimeSatudaryD: '8.00 h - 14.00 h',
    workTimeSatudaryP: 'Zatvoreno',
    workTimeSunday: 'Zatvoreno'
  },
  {
    name: 'Distributivni centar i Paket Shop Bugojno',
    address: 'Podgaj bb',
    location: [44.060231, 17.446427],
    workTimeWeekD: '8.00 h - 18.30 h',
    workTimeWeekP: '9.00 h - 18.00 h',
    workTimeSatudaryD: '8.00 h - 14.00 h',
    workTimeSatudaryP: 'Zatvoreno',
    workTimeSunday: 'Zatvoreno'
  },
  {
    name: 'Distributivni centar Prijedor',
    address: 'Svale bb',
    location: [44.9773353, 16.7327447],
    workTimeWeek: '8.00 h - 19.00 h',
    workTimeSatudary: '8.00 h - 14.00 h',
    workTimeSunday: 'Zatvoreno'
  },
  {
    name: 'Distributivni centar i Paket Shop Visoko',
    address: 'Ciglanska bb',
    location: [43.995064502178764, 18.170504880076635],
    workTimeWeekD: '8.00 h - 18.00 h',
    workTimeWeekP: '9.00 h - 18.00 h',
    workTimeSatudaryD: '8.00 h - 14.00 h',
    workTimeSatudaryP: 'Zatvoreno',
    workTimeSunday: 'Zatvoreno'
  },
  {
    name: 'Distributivni centar i Paket Shop Zenica',
    address: 'Željezarska bb',
    location: [44.211580, 17.906124],
    workTimeWeek: '8.00 h - 19.30 h',
    workTimeSatudaryD: '8.00 h - 14.00 h',
    workTimeSatudaryP: '8.00 h - 14.00 h',
    workTimeSunday: 'Zatvoreno'
  },
  {
    name: 'Paket Shop Banja Luka',
    address: 'Bulevar Desanke Maksimović 8, Starčevica',
    location: [44.763611821562456, 17.205705551926126],
    workTimeWeek: '8.00 h - 20.00 h',
    workTimeSatudary: 'Zatvoreno',
    workTimeSunday: 'Zatvoreno'
  },
];

export default class Map extends React.Component {
  constructor(props) {
    super(props);
    this.initMap = this.initMap.bind(this);
    this.state = {
      show: false
    };
  }

  initMap() {
    this.setState({
      _mapInit: true
    });
    var latLng = new window.google.maps.LatLng(43.9, 17.7721895);

    var map = new window.google.maps.Map(this.GoogleMap, {
      zoom: 7.9,
      center: latLng,
      mapTypeId: window.google.maps.MapTypeId.ROADMAP,
      disableDefaultUI: true,
      gestureHandling: "gestureHandling",
      styles: [
        {
          "elementType": "geometry",
          "stylers": [
            {
              "color": "#f5f5f5"
            }
          ]
        },
        {
          "elementType": "labels.icon",
          "stylers": [
            {
              "visibility": "off"
            }
          ]
        },
        {
          "elementType": "labels.text.fill",
          "stylers": [
            {
              "color": "#616161"
            }
          ]
        },
        {
          "elementType": "labels.text.stroke",
          "stylers": [
            {
              "color": "#f5f5f5"
            }
          ]
        },
        {
          "featureType": "administrative.country",
          "elementType": "geometry.stroke",
          "stylers": [
            {
              "color": "#c1cddf"
            },
            {
              "weight": 4
            }
          ]
        },
        {
          "featureType": "administrative.land_parcel",
          "elementType": "labels.text.fill",
          "stylers": [
            {
              "color": "#bdbdbd"
            }
          ]
        },
        {
          "featureType": "poi",
          "elementType": "geometry",
          "stylers": [
            {
              "color": "#eeeeee"
            }
          ]
        },
        {
          "featureType": "poi",
          "elementType": "labels.text.fill",
          "stylers": [
            {
              "color": "#757575"
            }
          ]
        },
        {
          "featureType": "poi.park",
          "elementType": "geometry",
          "stylers": [
            {
              "color": "#e5e5e5"
            }
          ]
        },
        {
          "featureType": "poi.park",
          "elementType": "labels.text.fill",
          "stylers": [
            {
              "color": "#9e9e9e"
            }
          ]
        },
        {
          "featureType": "road",
          "elementType": "geometry",
          "stylers": [
            {
              "color": "#ffffff"
            }
          ]
        },
        {
          "featureType": "road.arterial",
          "elementType": "labels.text.fill",
          "stylers": [
            {
              "color": "#757575"
            }
          ]
        },
        {
          "featureType": "road.highway",
          "elementType": "geometry",
          "stylers": [
            {
              "color": "#dadada"
            }
          ]
        },
        {
          "featureType": "road.highway",
          "elementType": "labels.text.fill",
          "stylers": [
            {
              "color": "#616161"
            }
          ]
        },
        {
          "featureType": "road.local",
          "elementType": "labels.text.fill",
          "stylers": [
            {
              "color": "#9e9e9e"
            }
          ]
        },
        {
          "featureType": "transit.line",
          "elementType": "geometry",
          "stylers": [
            {
              "color": "#e5e5e5"
            }
          ]
        },
        {
          "featureType": "transit.station",
          "elementType": "geometry",
          "stylers": [
            {
              "color": "#eeeeee"
            }
          ]
        },
        {
          "featureType": "water",
          "elementType": "geometry",
          "stylers": [
            {
              "color": "#c9c9c9"
            }
          ]
        },
        {
          "featureType": "water",
          "elementType": "geometry.fill",
          "stylers": [
            {
              "color": "#eeeeee"
            }
          ]
        },
        {
          "featureType": "water",
          "elementType": "labels.text.fill",
          "stylers": [
            {
              "color": "#9e9e9e"
            }
          ]
        }
      ]
    });

    for (let i = 0; i < locations.length; i++) {
      var marker = new window.google.maps.Marker({
        position: new window.google.maps.LatLng(locations[i].location[0], locations[i].location[1]),
        map: map,
        icon: mapIcon
      });
      marker.addListener('click', () => {
        this.setState({ show:locations[i] })
        console.log('test')
      })
    }
  }

  componentDidMount() {
    if (this.props.mapinit && !this.state._mapInit) {
      this.initMap();
    }

  }

  componentDidUpdate() {
    if (this.props.mapinit && !this.state._mapInit) {
      this.initMap();
    }
  }

  render() {
    console.log(this.state.show)
    let lat;
    let long;

    if(this.state.show && this.state.show.location) {
      lat = this.state.show.location[0];
      long = this.state.show.location[1];
    }

    return (
      <>
        {
          this.props.mapinit ?
            <div className="googleMap" ref={(input) => { this.GoogleMap = input; }}>
              {this.state.show ?
                <div className="googleModal">
                  <div className="googleBox">
                    <button onClick={() => this.setState({ show: null })} className="fa fa-close" />
                    <div className="textBox">
                      <p><span>{this.state.show.name}</span></p>
                      <p>{this.state.show.address}</p>
                      <h5 className="work-time-title">{this.state.show.workTimeWeek || this.state.show.workTimeWeekP || this.state.show.deliveryTimeWeek ? 'Radno vrijeme' : null}</h5>
                      <p style={{ fontWeight: 600 }}>{this.state.show.workTimeWeek  || this.state.show.workTimeWeekP || this.state.show.deliveryTimeWeek ? 'Radnim danima:' : null}</p>
                      {
                        this.state.show.workTimeWeekD || this.state.show.workTimeWeekP ?
                        <>
                      <p>Distributivni centar: {this.state.show.workTimeWeekD}</p>
                      <p>Paket Shop: {this.state.show.workTimeWeekP}</p>
                      </>
                      :
                      null
                      }
                      {
                        this.state.show.deliveryTimeWeek ?
                        <>
                        <p>Distributivni centar: {this.state.show.workTimeWeek}</p>
                        <p>Paket Shop</p>
                        <p>Dostava paketa: {this.state.show.deliveryTimeWeek}</p>
                        <p>Preuzimanje paketa: {this.state.show.takingTimeWeek}</p>
                        </>
                        :
                        null
                      }
                      {
                        this.state.show.deliveryTimeWeek ?
                        null
                        :
                        <p>{this.state.show.workTimeWeek ? this.state.show.workTimeWeek : null}</p>
                      }
                      <p style={{ fontWeight: 600 }}>{this.state.show.workTimeWeek || this.state.show.workTimeWeekP || this.state.show.deliveryTimeWeek ? 'Subotom:' : null}</p>
                      {
                        this.state.show.workTimeSatudaryD || this.state.show.workTimeSatudaryP ?
                        <>
                      <p>Distributivni centar: {this.state.show.workTimeSatudaryD}</p>
                      <p>Paket Shop: {this.state.show.workTimeSatudaryP}</p>
                      </>
                      :
                      null
                      }
                      {
                        this.state.show.deliveryTimeWeek ?
                        <p>{this.state.workTimeSatudary}</p>
                        :
                        null
                      }
                      { this.state.show.takingTimeSaturday ?
                      <>
                      <p>Dostava paketa: {this.state.show.deliveryTimeSaturday}</p>
                      <p>Preuzimanje paketa: {this.state.show.takingTimeSaturday}</p>
                      </>
                      :
                      null
                      }
                      <p>{this.state.show.workTimeSatudary ? this.state.show.workTimeSatudary : null}</p>
                      <p style={{ fontWeight: 600 }}>{this.state.show.workTimeSunday ? 'Nedjeljom:' : null}</p>
                      <p>{this.state.show.workTimeSunday ? this.state.show.workTimeSunday : null}</p>
                     {this.state.show.info &&  <p>{this.state.show.info}</p>}
                      <a className='open-map-button' href={`https://www.google.com/maps/search/?api=1&query=${lat},${long}`} target="_blank">Otvori na Google mapi</a>
                    </div>
                  </div>
                </div> : null}
            </div>

            : null

        }

      </>
    );
  }
}
