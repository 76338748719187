import React, { Component } from 'react'
import Navigacija from '../containers/navbar';
import News from '../components/news';
import Footer from '../containers/footer';
import { Link } from 'react-router-dom';
import newsDetailPicture from '../images/newsDetail.png';
import AnimtedButton from '../components/animatedButton';
import PageWithLayout from '../containers/page';
import moment from 'moment';

import {
    Container,
    Row,
    Col,
    NavItem, NavLink,
    Input, FormGroup, Table
} from 'reactstrap'



class Zones extends Component {
    constructor(props) {
        super(props);

        this.state = {
            zones: []
        }

        //45364723
    }


    componentDidMount() {


        fetch('https://gateway.euroexpress.ba:8083/api/v1/pub/zones').then((res) => res.json()).then((result) => {
            console.log(result)
            this.setState({
                zones: result
            })
        })

    }


    render() {
        const buttonText = "PRONAĐITE POŠILJKU";
        return (
            <div>
                <Container>
                    <Row noGutters>
                        <div className="bredkramb">
                            <h6>Zone dostava – pretraživač</h6>
                            <div className="links">
                                <NavItem>
                                    <Link to="/">Početna</Link>
                                </NavItem>
                                <NavItem>
                                    <Link to="/">Zone dostava – pretraživač</Link>
                                </NavItem>
                            </div>
                        </div>
                    </Row>
                </Container>
                <Container className="universalPageWrapper">
                    <Row>
                        <Col xl="12" lg="12" md="12" xs="12">
                            <p>Unesite poštanski broj ili naziv mjesta</p>
                            <Row>
                                <Col xl="6" lg="6" md="6" xs="12">
                                    <Input type="search" placeholder="Mjesto ili poštanski broj" value={this.state.search} onChange={(e) => {
                                        let res = [];
                                        let text = e.target.value
                                        if (text.length) {
                                            for (let i = 0; i < this.state.zones.length; i++) {
                                                if (this.state.zones[i].mjesto.toLowerCase().indexOf(text.toLowerCase()) == 0 || this.state.zones[i].posta.toLowerCase().indexOf(text.toLowerCase()) == 0) {
                                                    res.push(this.state.zones[i]);
                                                    /*if (res.length > 5)
                                                        break;*/
                                                }
                                            }
                                        }

                                        this.setState({
                                            results: res,
                                            search: text
                                        })
                                    }} />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    {this.state.results && this.state.results.length ?
                        <>

                            <Row>
                                <Col xl="12">
                                    <Table responsive>
                                        <thead>
                                            <tr>
                                                <th>Poš.broj</th>
                                                <th>Mjesto</th>
                                                <th>Dist. centar</th>
                                                <th>Pon.</th>
                                                <th>Uto.</th>
                                                <th>Sri.</th>
                                                <th>Čet.</th>
                                                <th>Pet.</th>
                                                <th>Sub.</th>
                                                <th>Zona I.</th>
                                                <th>Zona II.</th>

                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                this.state.results.map((item, idx) => {
                                                    return (
                                                        <tr>
                                                            <td>{item.posta}</td>
                                                            <td>{item.mjesto}</td>
                                                            <td>{item.dc_name}</td>
                                                            <td>{item.Pon ? <div className="green-circle"></div> :<div className="red-circle"></div> }</td>
                                                            <td>{item.Uto ? <div className="green-circle"></div> :<div className="red-circle"></div>}</td>
                                                            <td>{item.Sri ? <div className="green-circle"></div> :<div className="red-circle"></div>}</td>
                                                            <td>{item.Cet ? <div className="green-circle"></div> :<div className="red-circle"></div>}</td>
                                                            <td>{item.Pet ? <div className="green-circle"></div> :<div className="red-circle"></div>}</td>
                                                            <td>{item.Sub ? <div className="green-circle"></div> :<div className="red-circle"></div>}</td>
                                                            <td>{item.zona == 1 ? <div className="green-circle"></div> : <div className="red-circle"></div>}</td>
                                                            <td>{item.zona == 2 ? <div className="green-circle"></div> : <div className="red-circle"></div>}</td>

                                                        </tr>

                                                    )
                                                })
                                            }
                                        </tbody>
                                    </Table>
                                </Col>
                            </Row>
                        </>
                        :
                        null
                    }
                    <Row>
                        <Col xl="12">
                            <br />
                            <p>Poštovani/a, provjerite da li ste unijeli ispravan naziv mjesta ili poštanskog broja. Ukoliko vam se ne prikazuje rezultat pretraživanja moguće je da traženo mjesto ili poštanski broj nemamo u evidenciji. Molimo vas da pozovete Call Centar na info broj <Link to='/stranica/call-centar'>1331</Link> radi provjere traženih informacija.</p>
                        </Col>
                    </Row>

                </Container>
            </div>
        )
    }
}

export default PageWithLayout(Zones)
