import React, { Component } from 'react'
import GoogleMap from '../components/map';
import AnimtedButton from '../components/animatedButton';
import { Link } from 'react-router-dom';

import {
    Container,
    Row,
    Col,
} from 'reactstrap';

class googleMapSection extends Component {
    render() {
        const buttonText = "OPŠIRNIJE";
        const newButtonDesign = true;

        return (
            <div className="mapWrapper">
                <Container>
                    <Row>
                        <Col xl="4" lg="4" md = "5" xs = "12">
                            <div className="blueBox">
                                <h6>Mapa distributivnih centara u BiH</h6>
                            </div>
                            <div className="parahraphWrapper" style={{textAlign: 'justify'}}>
                                <p className="universalParagraph">Detaljne podatke o nekom od naših centara možete dobiti klikom na <span>crveni marker</span> označen na mapi.</p>
                                <p className="universalParagraph">Dobićete tačnu lokaciju centra, adresu i radno vrijeme.</p>
                                <Link to="/o-nama/mreza-nasih-poslovnica"><AnimtedButton buttonText={buttonText} newButtonDesign={newButtonDesign} /></Link>
                            </div>

                        </Col>
                        <GoogleMap {...this.props} />

                    </Row>

                </Container>
            </div>
        )
    }
}

export default googleMapSection
