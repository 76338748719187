import React, { Component } from 'react'
import Navigacija from '../../containers/navbar';
import News from '../../components/news';
import Footer from '../../containers/footer';
import { Link } from 'react-router-dom';
import { NavLink as RRNavLink } from 'react-router-dom';
import Picture8 from '../../images/picture8.jpg';
import PageWithLayout from '../../containers/page';
import MenuComponent from '../../components/menuComponent';

import {
    Container,
    Row,
    Col,
    NavItem, NavLink
} from 'reactstrap'

const links = [
    {
        _id: 1,
        link: '/zaposlenje/edukacije',
        name: 'Edukacije'
    },
    {
        _id: 2,
        link: '/zaposlenje/otvorena-radna-mjesta',
        name: 'Otvorena radna mjesta'
    },
    {
        _id: 3,
        link: '/zaposlenje/prijavi-se',
        name: 'Prijavi se'
    },
];

class OpenPositions extends Component {
    render() {
        return (
            
            <div>
                <Container>
                    <Row noGutters>
                        <div className="bredkramb">
                            <h6>Otvorena radna mjesta</h6>
                            <div className="links">
                                <NavItem>
                                    <Link to="/">Početna</Link>
                                </NavItem>
                                <NavItem>
                                    <Link to="/">Zasposlenje</Link>
                                </NavItem>
                                <NavItem>
                                    <Link to="/">Otvorena radna mjesta</Link>
                                </NavItem>
                            </div>
                        </div>
                    </Row>
                </Container>
                <Container className="universalPageWrapper">
                    <Row>
                        <Col xl="3" lg="3">
                            <div className="desktopSidemenu">
                                <h6>Meni</h6>
                                <MenuComponent links={links}/>

                            </div>
                        </Col>
                        <Col xl="9" lg="9" md="12" xs="12">
                            <h6>Otvorena radna mjesta</h6>
                            <p className="smallParahraph">Za područje grada Banjaluka raspisujemo konkurs za radno mjesto:</p>
                            <h5>Referent prodaje u paket šopu</h5>
                            <p><span>Opis radnog mjesta:</span></p>
                            <div>
                                <li>preuzimanje/isporuka pošiljaka na zahtjev klijenta,</li>
                                <li>unos preuzetih pošiljaka u informacioni sistem,</li>
                                <li>naplata poštarine za preuzete/isporučene pošiljke i izdavanje gotovinskih računa,</li>
                                <li>uredno zaduženje/razduženje pošiljaka kroz transportne akcije,</li>
                                <li>informisanje primalaca o pošiljkama koje su pristigle u paket šop za isporuku,</li>
                                <li>rješavanje problematike prilikom isporuke pošiljaka (otvaranje paketa, provjera broja telefona, provjera adrese),</li>
                                <li>prosljeđivanje ostale problematike koja se odnosi na isporuku pošiljaka korisničkom servisu,</li>
                                <li>promocija i prezentacija usluga EE i prikupljanje informacija o potencijalnim komitentima,</li>
                                <li>vođenje izvještaja o radu paket šopa.</li>
                            </div>
                            <p><span>Opšti uslovi</span></p>
                            <p className="smallParahraph">SSS – IV stepen, poželjno radno iskustvo na istim ili sličnim poslovima, poznavanje rada na računaru u Windows okruženju i Microsoft Office paketa.</p>
                            <p><span>Posebne vještine:</span></p>
                            <p className="smallParahraph">Dobro razvijene organizacione i komunikacijske sposobnosti, pregovaračke vještine, ljubaznost, uslužnost, kooperativnost, sposobnost prilagođavanja timskom radu.</p>
                            <p className="smallParahraph">Sve vaše prijave sa CV-jem i popratnim pismom šaljite na <span>posao@euroexpress.ba</span></p>
                            <div className="infoWrap">
                                <div>
                                    <p className="smallParahraph"><span>Grad:</span></p>&nbsp;<p className="smallParahraph">Banja Luka</p>
                                </div>
                                <div>
                                    <p className="smallParahraph"><span>Država:</span></p>&nbsp;<p className="smallParahraph">Bosna i Hercegovina</p>
                                </div>
                                <div>
                                    <p className="smallParahraph"><span>Broj radnih mjesta:</span></p>&nbsp;<p className="smallParahraph">1</p>
                                </div>
                                <div>
                                    <p className="smallParahraph"><span>Datum objave:</span></p>&nbsp;<p className="smallParahraph">08.07.2019. godine</p>
                                </div>
                                <div>
                                    <p className="smallParahraph"><span>Oglas traje do:</span></p>&nbsp;<p className="smallParahraph">22.07.2019. godine</p>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
                <News />

            </div>
        )
    }
}

export default PageWithLayout(OpenPositions)
