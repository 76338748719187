import React, { Component } from 'react'
import Isvg from 'react-inlinesvg';
import Logo from '../images/svg/logo.svg';
import StrelicaDole from '../images/svg/strelicaDole.svg';
import { NavLink as RRNavLink } from 'react-router-dom';
import { Link } from 'react-router-dom';
import headerBG from '../images/headerBG.png';
import mobileHeaderBG from '../images/mobileHeaderBG.png';
import headerAccountIcon from '../images/svg/headerAccount.svg';
import headerLocationIcon from '../images/svg/headerLocation.svg';
import headerBGNotHome from '../images/header.png';
import Close from '../images/svg/close.svg'
import arrowLeft from '../images/svg/arrow-left.svg'
import arrowRight from '../images/svg/arrow-right.svg'
import serviceSvg2 from '../images/svg/service-svg2.svg';
import serviceSvg4 from '../images/svg/service-svg4.svg';



import {
    Container,
    Row,
    Col,
    Nav,
    Navbar,
    NavItem,
    NavLink,
    Collapse,
    DropdownToggle,
    DropdownMenu,
    UncontrolledDropdown
} from 'reactstrap';
import { API_ENDPOINT } from '../constants';

class Navigacija extends Component {
    constructor(props) {
        super(props)
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
        this.state = { aboutus: [], services: [], employments: [], width: 0, height: 0, otvoren: false, submenu: false, submenu1: false, submenu2: false, submenu3: false, submenu4: false, submenu5: false, submenu6: false, submenu7: false, submenu8: false };

    }
    componentDidMount() {
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);

        fetch(API_ENDPOINT + '/aboutus', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            }
        }).then((res) => res.json()).then((result) => {
            console.log(result);
            this.setState({
                aboutus: result
            })

        })



        fetch(API_ENDPOINT + '/services', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            }
        }).then((res) => res.json()).then((result) => {
            console.log(result);
            this.setState({
                services: result
            })

        })
        fetch(API_ENDPOINT + '/employments', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            }
        }).then((res) => res.json()).then((result) => {
            console.log(result);
            this.setState({
                employments: result
            })

        })



    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    updateWindowDimensions() {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
    }



    funkcija(lokacije) {
        if (lokacije === "/") {
            this.setState({ submenu: true })
        }
        if (lokacije === "/o-nama/istorija" || lokacije === "/o-nama/misija-i-vizija" || lokacije === "/o-nama/uprava-kompanije" || lokacije === "/o-nama/mreža-naših-poslovnica") {
            this.setState({ submenu: false, submenu1: true })
        }
        if (lokacije === "/naše-osnovne-usluge/domaći-transport" || lokacije === "/naše-osnovne-usluge/međunarodni-transport") {
            this.setState({ submenu3: true })
        }
        if (lokacije === "/dodatne-usluge/tenderska-dokumentacija") {
            this.setState({ submenu4: true })
        }
        if (lokacije === "/usluge-na-poseban-zahtjev/kurir-na-dan") {
            this.setState({ submenu5: true })
        }
        if (lokacije === "/novosti/aktuelna-dešavanja") {
            this.setState({ submenu6: true })
        }
        if (lokacije === "/zaposlenje/edukacije") {
            this.setState({ submenu7: true })
        }
        if (lokacije === "/kontakt/kontakt-formular") {
            this.setState({ submenu8: true })
        }
    }

    render() {
        const location = this.props[0].location.pathname;
        return (

            <>

                {this.state.width > 767 && this.props.home ?
                    <img src={headerBG} className="headerBG" />
                    : this.state.width < 767 ? <img src={mobileHeaderBG} className="headerBG" /> : null
                }



                <Container className="Navigacija">


                    {!this.props.home && this.state.width > 767 ? <img src={headerBGNotHome} className="headerBG2" /> : null}
                    {this.state.width > 991 ?
                        <Row>
                            <Col xl="3" lg="3">
                                <Link to="/"><Isvg src={Logo} className="headerLogo" animateIn="bounce" /></Link>
                            </Col>
                            <Col xl="9" lg="9">
                                <div className="header-top">
                                    <div className="links">
                                        <a target="_blank" href="https://files.euroexpress.ba/ISOcertifikat.pdf">ISO certifikat</a>

                                    </div>
                                    <div className="links-icon">
                                    <a href="https://mobile.euroexpress.ba/"><button><Isvg src={headerAccountIcon} /> </button> WEB KLIJENT</a>
                                <Link to="/praćenje-pošiljke"><button><Isvg src={headerLocationIcon} /> </button> PRAĆENJE POŠILJKE</Link>
                                <Link to="/slanje-posiljke"><button><Isvg src={serviceSvg2} /> </button> SLANJE POŠILJKE</Link>
                                <Link to="/zone-dostava"><button><Isvg src={serviceSvg4} /> </button> ZONE DOSTAVA</Link>

                                    </div>
                                    <div className="spacer"></div>
                                </div>

                                <div className="navbar navbar-expand-lg">
                                    <NavItem className="nav-item">
                                        <NavLink exact tag={RRNavLink} className="nav-link" to="/" activeClassName="active">POČETNA
                                </NavLink>
                                    </NavItem>
                                    <UncontrolledDropdown nav inNavbar>
                                        <DropdownToggle nav caret href="" className={location === "/o-nama/istorija" || location === "/o-nama/misija-i-vizija" || location === "/o-nama/uprava-kompanije" || location === "/o-nama/mreža-naših-poslovnica" ? "active" : null}>O NAMA <Isvg src={StrelicaDole} /></DropdownToggle>
                                        <DropdownMenu>
                                            {
                                                this.state.aboutus.map((item, idx) => {
                                                    return (
                                                        <NavLink tag={RRNavLink} to={`/o-nama/${item.alias}`} className="dropdown-item">{item.name}</NavLink>

                                                    )
                                                })
                                            }
                                           {/* <NavLink tag={RRNavLink} to="/stranica/kontakti" className="dropdown-item">Kontakti</NavLink>*/}

                                        </DropdownMenu>
                                    </UncontrolledDropdown>

                                    <UncontrolledDropdown nav inNavbar>
                                        <DropdownToggle nav caret href="" className={location === "/naše-osnovne-usluge/domaći-transport" || location === "/dodatne-usluge/tenderska-dokumentacija" || location === "/usluge-na-poseban-zahtjev/kurir-na-dan" ? "active" : null}>NAŠE USLUGE <Isvg src={StrelicaDole} /></DropdownToggle>
                                        <DropdownMenu>
                                            {
                                                this.state.services.map((item, idx) => {
                                                    if (!item.parent && item.alias != 'dodatne-usluge' && item.alias != 'usluge-na-poseban-zahtjev' && item.alias != 'online-usluge') {
                                                        return (
                                                            <UncontrolledDropdown nav inNavbar>
                                                                <DropdownToggle nav caret href="" activeClassName="active">{item.name} <Isvg src={StrelicaDole} /></DropdownToggle>
                                                                <DropdownMenu>
                                                                    {
                                                                        this.state.services.map((sub, sidx) => {
                                                                            if (sub.parent == item._id)
                                                                                return (
                                                                                    <NavLink tag={RRNavLink} to={`/usluge/${item.alias}/${sub.alias}`} className="dropdown-item">{sub.name}</NavLink>
                                                                                )
                                                                        })
                                                                    }
                                                                </DropdownMenu>
                                                            </UncontrolledDropdown>

                                                        )
                                                    } else if (!item.parent && (item.alias == 'dodatne-usluge' || item.alias == 'usluge-na-poseban-zahtjev' || item.alias == 'online-usluge') ) {
                                                        return (
                                                            <NavLink tag={RRNavLink} to={`/usluge/${item.alias}`} className="dropdown-item">{item.name}</NavLink>

                                                        )
                                                    }
                                                })
                                            }

                                        </DropdownMenu>

                                    </UncontrolledDropdown>
                                    <NavItem className="nav-item">
                                        <NavLink exact tag={RRNavLink} className="nav-link" to="/stranica/pakovanje-posiljki" activeClassName="active">PAKOVANJE POŠILJKI</NavLink>
                                    </NavItem>

                                    <UncontrolledDropdown nav inNavbar>
                                        <DropdownToggle nav caret href="" className={location === "/novosti/aktuelna-dešavanja" || location === "/novosti/detalji" ? "active" : null}>NOVOSTI <Isvg src={StrelicaDole} /></DropdownToggle>
                                        <DropdownMenu>
                                            <NavLink tag={RRNavLink} to="/novosti/aktuelna-dešavanja" className="dropdown-item">Aktuelna dešavanja</NavLink>
                                            <NavLink tag={RRNavLink} to="/novosti/kutak-za-medije" className="dropdown-item">Kutak za medije</NavLink>
                                            <NavLink tag={RRNavLink} to="/stranica/kontakti" className="dropdown-item">Kontakti</NavLink>

                                        </DropdownMenu>
                                    </UncontrolledDropdown>
                                    <UncontrolledDropdown nav inNavbar>
                                        <DropdownToggle nav caret href="" className={location === "/zaposlenje/edukacije" || location === "/zaposlenje/otvorena-radna-mjesta" ? "active" : null}>ZAPOSLENJE <Isvg src={StrelicaDole} /></DropdownToggle>
                                        <DropdownMenu>

                                            {
                                                this.state.employments.map((item, idx) => {
                                                    return (
                                                        <NavLink tag={RRNavLink} to={`/zaposlenje/stranica/${item.alias}`} className="dropdown-item">{item.name}</NavLink>

                                                    )
                                                })
                                            }

                                            <NavLink tag={RRNavLink} to="/zaposlenje/prijavi-se" className="dropdown-item">Prijavi se</NavLink>
                                        </DropdownMenu>
                                    </UncontrolledDropdown>



                                </div>
                            </Col>
                        </Row> :
                        <>
                            <Container>
                                <Row>
                                    <Col xs="6" md="3" style={{ zIndex: 1 }}>
                                        <Link to="/"><Isvg src={Logo} className="headerLogo" animateIn="bounce" /></Link>
                                    </Col>
                                    <Col xs="6" md="9" style={{ zIndex: 1 }}>
                                        <button className="btn-menu" onClick={() => this.setState({ otvoren: !this.state.otvoren }, this.funkcija(location))}> <i className="fa fa-bars lg"></i></button>
                                    </Col>
                                </Row>
                            </Container>
                            <div className={this.state.otvoren ? 'navbarResposniveOpen2' : 'navbarResposnive2'}>
                                <div className={this.state.otvoren ? 'sadrzajOpen2' : 'sadrzaj2'} >
                                    <button onClick={() => this.setState({ otvoren: !this.state.otvoren, submenu: false })} className="closeIcon"><Isvg src={Close}></Isvg></button>
                                    <div style={{ overflow: 'hidden' }}>
                                        <ul className={this.state.submenu ? "content" : "content"}>
                                            <li><Link to='/' className="nav-link">Početna</Link></li>
                                            <button onClick={() => this.setState({ submenu1: !this.state.submenu1 })} className="arrowRight">O nama<Isvg src={arrowLeft}></Isvg></button>
                                            <button onClick={() => this.setState({ submenu2: !this.state.submenu2 })} className="arrowRight">Naše usluge<Isvg src={arrowLeft}></Isvg></button>
                                            <li><Link to='/stranica/pakovanje-posiljki' className="nav-link">Pakovanje pošiljki</Link></li>

                                            <button onClick={() => this.setState({ submenu6: !this.state.submenu6 })} className="arrowRight">Novosti<Isvg src={arrowLeft}></Isvg></button>
                                            <button onClick={() => this.setState({ submenu7: !this.state.submenu7 })} className="arrowRight">Zaposlenje<Isvg src={arrowLeft}></Isvg></button>
                                        </ul>
                                    </div>
                                    <div style={{ overflow: 'hidden' }}>
                                        <ul className={this.state.submenu1 ? "content2" : "noContent2"}>
                                            <button onClick={() => this.setState({ submenu1: !this.state.submenu1, submenu: true })} className="arrowLeft"><Isvg src={arrowRight}></Isvg></button>
                                            {
                                                this.state.aboutus.map((item, idx) => {
                                                    return (
                                                        <li onClick={() => this.setState({ otvoren: null })}><Link to={`/o-nama/${item.alias}`} className={location === `/o-nama/${item.alias}` ? "nav-link active" : "nav-link"}>{item.name}</Link></li>

                                                    )
                                                })

                                            }
                                           {/* <li onClick={() => this.setState({otvoren: null})}><Link to='/kontakti' className={location === "/kontakti" ? "nav-link active" : "nav-link"}>Kontakti</Link></li> */}

                                        </ul>
                                    </div>
                                    <div style={{ overflow: 'hidden' }}>
                                        <ul className={this.state.submenu2 ? 'content2' : 'noContent2'}>
                                            <button onClick={() => this.setState({ submenu2: !this.state.submenu2, submenu: true })} className="arrowLeft"><Isvg src={arrowRight}></Isvg></button>
                                            {
                                                this.state.services.map((item, idx) => {
                                                    if (!item.parent && item.alias !== 'dodatne-usluge' && item.alias !== 'usluge-na-poseban-zahtjev' && item.alias !== 'online-usluge') {
                                                        return (
                                                            <button onClick={() => this.setState({ submenu3: item })} className="arrowRight">{item.name}<Isvg src={arrowLeft}></Isvg></button>
                                                        )
                                                    } else if (!item.parent && (item.alias == 'dodatne-usluge' || item.alias == 'usluge-na-poseban-zahtjev' || item.alias == 'online-usluge' ) ) {
                                                        return (
                                                            <li onClick={() => this.setState({ otvoren: null })}><Link to={`/usluge/${item.alias}`} className="nav-link">{item.name}</Link></li>

                                                        )
                                                    }
                                                })
                                            }
                                        </ul>
                                    </div>
                                    <div style={{ overflow: 'hidden' }}>
                                        <ul className={this.state.submenu3 ? 'content2' : 'noContent2'}>
                                            <button onClick={() => this.setState({ submenu3: null, submenu2: true })} className="arrowLeft"><Isvg src={arrowRight}></Isvg></button>
                                            {
                                                this.state.submenu3 && this.state.services.map((item, idx) => {
                                                    if (item.parent == this.state.submenu3._id)
                                                        return (
                                                            <li onClick={() => this.setState({ otvoren: null })}><Link to={`/usluge/${this.state.submenu3.alias}/${item.alias}`} className={location === `/usluge/${this.state.submenu3.alias}/${item.alias}` ? "nav-link active" : "nav-link"}>{item.name}</Link></li>

                                                        )
                                                })
                                            }
                                        </ul>
                                    </div>
                                    <div style={{ overflow: 'hidden' }}>
                                        <ul className={this.state.submenu6 ? 'content2' : 'noContent2'}>
                                            <button onClick={() => this.setState({ submenu6: !this.state.submenu6, })} className="arrowLeft"><Isvg src={arrowRight}></Isvg></button>
                                            <li onClick={() => this.setState({otvoren: null})}><Link to='/novosti/aktuelna-dešavanja' className={location === "/novosti/aktuelna-dešavanja" ? "nav-link active" : "nav-link"}>Aktuelna dešavanja</Link></li>
                                            <li onClick={() => this.setState({otvoren: null})}><Link to='/novosti/kutak-za-medije' className={location === "/novosti/kutak-za-medije" ? "nav-link active" : "nav-link"}>Kutak za medije</Link></li>
                                            <li onClick={() => this.setState({otvoren: null})}><Link to='/kontakti' className={location === "/kontakti" ? "nav-link active" : "nav-link"}>Kontakti</Link></li>
                                        </ul>
                                    </div>
                                    <div style={{ overflow: 'hidden' }}>
                                        <ul className={this.state.submenu7 ? 'content2' : 'noContent2'}>
                                            <button onClick={() => this.setState({ submenu7: !this.state.submenu7 })} className="arrowLeft"><Isvg src={arrowRight}></Isvg></button>
                                            {
                                                this.state.employments.map((item, idx) => {
                                                    return (
                                                        <li onClick={() => this.setState({otvoren: null})}><Link to={`/zaposlenje/stranica/${item.alias}`} className={location === `/zaposlenje/stranica/${item.alias}` ? "nav-link active" : "nav-link"}>{item.name}</Link></li>

                                                    )
                                                })
                                            }

                                            <li onClick={() => this.setState({otvoren: null})}><Link to='/zaposlenje/prijavi-se' className={location === "/zaposlenje/prijavi-se" ? "nav-link active" : "nav-link"}>Prijavi se</Link></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </>
                    }
                    <Row noGutters>
                        <div className="headerIconsWrapper">
                            <div>
                                <a href="https://webclient.euroexpress.ba/"><button><Isvg src={headerAccountIcon} /></button></a>
                                <Link to="/praćenje-pošiljke"><button><Isvg src={headerLocationIcon} /></button></Link>
                            </div>
                        </div>

                             <Link className="link-no-decoration" to='/stranica/call-centar'>  <div className="info">
                                <h6>INFO BROJ</h6>
                                <h1>1331</h1>
                            </div></Link>

                    </Row>
                </Container>
            </>
        )
    }
}

export default Navigacija;
