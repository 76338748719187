import React, { Component } from 'react';
import { Field, reduxForm, formValueSelector } from 'redux-form'
import { Link } from 'react-router-dom';
import { connect } from 'react-redux'

import Text from './fields/text';
import Textarea from './fields/textarea';

import Select from './fields/select';
import Check from './fields/check';
import ChooseFile from './fields/file';
import Check1 from './fields/check1';

import {
    Container,
    Row,
    Col,
    Nav,
    Navbar,
    NavItem,
    NavLink,
    Collapse,
    DropdownToggle,
    DropdownMenu,
    UncontrolledDropdown
} from 'reactstrap';
import AnimatedButton from '../animatedButton';


const required = value => value ? undefined : "Required"


const renderCheckField = ({
    input,
    placeholder,
    label,
    meta: { touched, error },
    translate,
    invert
}) => (

        <Check
            label={label}
            translate={translate}
            invert={invert}
            {...input}
        />
    )


const renderTextField = ({
    input,
    placeholder,
    label,
    type,
    meta: { touched, error },
}) => (

        <Text
            placeholder={placeholder}
            label={label}
            type={type}
            errorText={touched && error}
            error={touched && error}

            {...input}
        />
    )
const renderTextareaField = ({
    input,
    placeholder,
    label,
    type,
    meta: { touched, error },
}) => (

        <Textarea
            placeholder={placeholder}
            label={label}
            type={type}
            errorText={touched && error}
            error={touched && error}

            {...input}
        />
    )

const renderSelectField = ({
    input,
    placeholder,
    label,
    meta: { touched, error },
    children,
}) => (

        <Select
            placeholder={placeholder}
            label={label}
            errorText={touched && error}
            error={touched && error}
            {...input}
            children={children}
        />
    )
const renderChooseFile = ({
    input,
    placeholder,
    meta: { touched, error },
    accept
}) => (

        <ChooseFile
            placeholder={placeholder}
            errorText={touched && error}
            error={touched && error}
            {...input}
            accept={accept}
        />
    )

class form extends React.Component {
    constructor(props) {
        super(props);

        this.state = {}
    }


    componentDidUpdate(prevProps) {
    }


    render() {

        const { handleSubmit, pristine, reset, submitting } = this.props;
        console.log(pristine, submitting);

        return (

            <form onSubmit={handleSubmit} className="form-wrapper">
                <Container>
                    <Row>
                        <Col lg="9">

                            <Container>
                                <Row>

                                    <Col lg="12">
                                        <Check1 label="Saglasnost za obradu ličnih podataka*" value={this.state.check} onChange={(val) => this.setState({ check: val })} />
                                        <p className="hint">Tekst saglasnosti možete pogledati <a onClick={() => this.setState({ modal: true })}>ovdje.</a></p>

                                    </Col>
                                    {
                                        this.state.modal ?
                                            <div className="text-modal">
                                                <div>
                                                    <h3>Saglasnost za obradu ličnih podataka</h3>
                                                    <p>Izjavljujem da lične podatke uključujući i podatke iz priloženih dokumenata, dobrovoljno stavljam na raspolaganje EuroExpress d.o.o. Banja Luka (u daljem tekstu: EuroExpress brza pošta) te da sam saglasan da EuroExpress brza pošta iste može obrađivati u cilju angažmana kandidata u EuroExpress brzoj pošti. Dozvoljavam Euro Express brzoj pošti da moje lične podatke može koristiti u evidencijama Euro Express brze pošte koje se odnose na selekciju i regrutaciju kadrova za vrijeme koje je potrebno za namjenu za koju se lični podaci obrađuju, ukoliko drugačije nije određeno važećim propisima. Saglasnost za obradu mojih podataka važi neodređeno vrijeme, a obavješten sam da istu mogu povući u bilo koje vrijeme, pisanim zahtjevom koju može dostaviti na e-mail adresu zastitalicnihpodataka@euroexpress.ba ili poštom na adresu Jovana Dučića 23A, 78000 Banja Luka, Republika Srpska, Bosna i Hercegovina.</p>
                                                    <div className="button-wrap">
                                                        <AnimatedButton handleClick={() => this.setState({modal: null})} type="button" buttonText="ZATVORI" />
                                                    </div>
                                                </div>
                                            </div>
                                            :

                                            null
                                    }


                                    <Col lg="6">
                                        <p>Ime i prezime<span>*</span></p>
                                        <Field
                                            name="name"
                                            type="text"
                                            component={renderTextField}
                                            placeholder="Unesite ime i prezime"
                                            validate={required}

                                        />
                                    </Col>

                                    <Col lg="6">
                                        <p>Datum rođenja <span>*</span></p>
                                        <Field
                                            name="dateOfBirth"
                                            type="text"
                                            component={renderTextField}
                                            placeholder="Unesite datum rođenja"
                                            validate={required}

                                        />
                                    </Col>
                                    <Col lg="12">
                                        <p>Adresa i mjesto stanovanja <span>*</span></p>
                                        <Field
                                            name="address"
                                            type="text"
                                            component={renderTextField}
                                            placeholder="Unesite adresu i mjesto stanovanja"
                                            validate={required}

                                        />
                                    </Col>


                                    <Col lg="6">
                                        <p>Telefon <span>*</span></p>

                                        <Field
                                            name="phoneNumber"
                                            type="text"
                                            component={renderTextField}
                                            placeholder="+387"
                                            validate={required}

                                        />
                                        <p className="sub" style={{marginTop: -16, paddingBottom: 10}}> Format: +387XXXXXXXX</p>

                                    </Col>
                                    <Col lg="6">
                                        <p>E-mail</p>
                                        <Field
                                            name="email"
                                            type="text"
                                            component={renderTextField}
                                            placeholder="Unesite E-mail adresu"

                                        />
                                    </Col>
                                    <Col lg="12">
                                        <p>Stručna sprema <span>*</span></p>
                                        <Field
                                            name="education"
                                            type="text"
                                            component={renderSelectField}
                                            placeholder="Izaberite stučnu spremu"
                                            validate={required}

                                        >
                                            <option value="MR">MR</option>
                                            <option value="VSS">VSS</option>
                                            <option value="Bachelor of Science">Bachelor of Science</option>
                                            <option value="VŠS">VŠS</option>
                                            <option value="SSS">SSS</option>
                                        </Field>
                                    </Col>

                                    <Col lg="6">
                                        <p>Zanimanje <span>*</span></p>
                                        <Field
                                            name="school"
                                            type="text"
                                            component={renderTextField}
                                            placeholder="Unesite zanimanje"
                                            validate={required}

                                        />
                                    </Col>
                                    <Col lg="6">
                                        <p>Radno mjesto za koje se prijavljujete <span>*</span></p>
                                        <Field
                                            name="workplace"
                                            type="text"
                                            component={renderSelectField}
                                            placeholder="Izaberite opciju"
                                            validate={required}

                                        >
                                            <option value="Kurir - dostavljač">Kurir - dostavljač</option>
                                            <option value="Vozač kamiona">Vozač kamiona</option>
                                            <option value="Magacioner">Magacioner</option>
                                            <option value="Logistika - transport">Logistika - transport</option>
                                            <option value="Pozivni centar">Pozivni centar</option>
                                            <option value="Komercijala - prodaja">Komercijala - prodaja</option>
                                            <option value="Administracija">Administracija</option>
                                        </Field>
                                    </Col>

                                    <Col lg="12">
                                        <p>Dosadašnje radno iskustvo</p>
                                        <Field
                                            name="workExperience"
                                            component={renderTextareaField}
                                            placeholder="Poslodavac, radno mjesto, period"

                                        ></Field>
                                    </Col>
                                    <Col lg="6">
                                        <p>Engleski jezik</p>
                                        <Field
                                            name="english"
                                            type="text"
                                            component={renderSelectField}
                                            placeholder="Izaberite nivo"

                                        >
                                            <option value="Napredni nivo">Napredni nivo</option>
                                            <option value="Srednji nivo">Srednji nivo</option>
                                            <option value="Početni nivo">Početni nivo</option>
                                        </Field>
                                    </Col>
                                    <Col lg="6">
                                        <p>Vozačka dozvola</p>
                                        <Field
                                            name="driversLicense"
                                            type="text"
                                            component={renderSelectField}
                                            placeholder="Izaberite opciju"

                                        >
                                            <option value="ne">Ne</option>
                                            <option value="da">Da</option>

                                        </Field>
                                    </Col>


                                    <Col lg="6">
                                        <p>Pismo motivacije</p>
                                        <Field
                                            name="motivationLetter"
                                            type="text"
                                            accept="application/pdf"
                                            component={renderChooseFile}
                                            placeholder=""

                                        />
                                    </Col>
                                    <Col lg="6">
                                        <p>CV/Biografija</p>
                                        <Field
                                            name="cv"
                                            type="text"
                                            accept="application/pdf"
                                            component={renderChooseFile}
                                            placeholder=""

                                        />
                                    </Col>



                                    {this.state.check ?
                                        <Col lg="3">
                                            <AnimatedButton type="submit" buttonText="POŠALJI"></AnimatedButton>
                                        </Col>
                                        :
                                        null
                                    }
                                </Row>
                            </Container>
                        </Col>

                        <Col lg="3">
                            <div className="price-modal">
                                <h6>Vaši podaci</h6>
                                <p>Obavezna polja za unos podataka su označena zvjezdicom *</p>

                            </div>
                        </Col>
                    </Row>
                </Container>

            </form>
        )
    }
}

export default reduxForm({
    form: 'form'  // a unique identifier for this form
})(form)



