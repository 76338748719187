import React, { Component } from 'react'
import News from '../../components/news';
import { Link } from 'react-router-dom';
import { NavLink as RRNavLink } from 'react-router-dom';
import PageWithLayout from '../../containers/page';
import GoogleMap1 from '../../components/map';
import GoogleMap2  from '../../components/map2';
import PinIcon from '../../images/pin.png';

import {
    Container,
    Row,
    Col,
    NavItem, NavLink
} from 'reactstrap'
import { API_ENDPOINT } from '../../constants';


class about extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pages: []
        }
    }

    componentDidMount() {
        fetch(API_ENDPOINT + '/aboutus', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            }
        }).then((res) => res.json()).then((result) => {
            console.log(result);
            this.setState({
                pages: result
            })

        })


    }


    render() {
        let page = {
            sections: []
        };

        for (let i = 0; i < this.state.pages.length; i++) {
            if (this.state.pages[i].alias == this.props[0].match.params.alias) {
                page = this.state.pages[i];
                break;
            }
        }

        return (
            <div>
                <Container>
                    <Row noGutters>
                        <div className="bredkramb">
                            <h6>O nama</h6>
                            <div className="links">

                                <NavItem>
                                    <Link to="/">Početna</Link>
                                </NavItem>
                                <NavItem>
                                    <Link to="/o-nama">O Nama</Link>
                                </NavItem>
                                <NavItem>
                                    <Link to={`/o-nama/${page.alias}`}>{page.name}</Link>
                                </NavItem>
                            </div>
                        </div>
                    </Row>
                </Container>
                <Container className="universalPageWrapper">
                    <Row>
                        <Col xl="3" lg="3">
                            <div className="desktopSidemenu">
                                <h6>Meni</h6>
                                {
                                    this.state.pages.map((item, idx) => {
                                        return (
                                            <NavItem>
                                                <NavLink to={`/o-nama/${item.alias}`} exact tag={RRNavLink} activeClassName="active">
                                                    {item.name}
                                                </NavLink>
                                            </NavItem>

                                        )
                                    })
                                }
                                {/*<NavItem>
                                    <NavLink to={`/stranica/kontakti`} exact tag={RRNavLink} activeClassName="active">
                                        Kontakti
                                                </NavLink>
                                </NavItem>*/}

                            </div>
                        </Col>
                        <Col xl="9" lg="9" md="12" xs="12">
                            <Container>
                                <Row>
                                    <Col lg="12">
                                        <h6>{page.name}</h6>
                                    </Col>
                                    {
                                        page.sections.map((item, idx) => {
                                            return (
                                                <Col lg={item.width.desktop} xs={item.width.mobile}>
                                                    {
                                                        item.value.type == 'image' ?
                                                            <img src={item.value.value} className="img-fluid" />

                                                            :
                                                            <div dangerouslySetInnerHTML={{ __html: item.value.value }}></div>
                                                    }
                                                </Col>
                                            )
                                        })
                                    }
                                </Row>
                            </Container>
                        </Col>

                    </Row>
                </Container>
                {
                    page.alias == 'mreza-nasih-poslovnica' ?
                        <div className="mapWrapper mapWrapper1">
                            <GoogleMap1 {...this.props}></GoogleMap1>
                        </div>
                        :
                        null
                }
                {
                    page.alias == 'petrol-benzinske-stanice' ?
                        <div className="mapWrapper mapWrapper1 map-relative">
                            <div>
                                <GoogleMap2 {...this.props}></GoogleMap2>
                            </div>
                        </div>
                        :
                        null
                }
                <News />

            </div>
        )
    }
}

export default PageWithLayout(about)
