import React, { Component } from 'react';
import Navigacija from '../containers/navbar';
import Karousel from '../containers/carousel';
import Isvg from 'react-inlinesvg';
import ServicesSection from '../containers/servicesSection';
import AboutSection from '../containers/aboutSection';
import ShipingSafetySection from '../containers/shipingSafetySection';
import { Link } from 'react-router-dom';
import News from '../components/news';
import GoogleMapSection from '../containers/googleMapSection';
import EducationSection from '../containers/educationSection';
import Footer from '../containers/footer';
import PageWithLayout from '../containers/page';
import AnimtedButton from '../components/animatedButton';
import cars from '../images/cars.png';

// baloons import
// import bijeliLogo from '../../src/images/bijeli-logo.png'
// import bijeliVjetar from '../../src/images/bijeli-vjetar.png'
// import crveniLogo from '../../src/images/crveni-logo.png'
// import crveniVjetar from '../../src/images/crveni-vjetar.png'
// import plaviLogo from '../../src/images/plavi-logo.png'
// import plaviVjetar from '../../src/images/plavi-vjetar.png'

import {
    Container,
    Row,
    Col
} from 'reactstrap';
import { API_ENDPOINT } from '../constants';

// const baloni = [bijeliLogo, bijeliVjetar, crveniLogo, crveniVjetar, plaviLogo, plaviVjetar]


class Home extends React.Component {
    constructor(props) {
        super(props);

        // this.random = this.random.bind(this);
        // this.getRandomStyles = this.getRandomStyles.bind(this);
        // this.createBalloon = this.createBalloon.bind(this);
        // this.removeBalloons = this.removeBalloons.bind(this);

        this.state = {

        }
    }

    // ----------------- balloons -----------------

    // balloonContainer = ''

    // removeBalloons() {
    //     this.balloonContainer.style.opacity = 0;
    //     setTimeout(() => {
    //         this.balloonContainer.remove()
    //     }, 500)
    // }


    getRandomStyles() {
        var dur = this.random(5) + 17;
        var ml = this.random(240);
        var mt = this.random(200);
        return `
    margin: ${mt}px 0 0 ${ml}px;
    opacity: 1;
  animation: float ${dur}s ease-in infinite
  `;
    }

    random(num) {
        return Math.floor(Math.random() * num);
    }

    // createBalloon(num) {
    //     console.log("pokrenuta funkcija")
    //
    //     for (var i = num; i > 0; i--) {
    //         var balloon = document.createElement("img");
    //         balloon.className = "balloon";
    //         balloon.src = baloni[this.random(6)];
    //         balloon.style.cssText = this.getRandomStyles();
    //         this.balloonContainer && this.balloonContainer.append(balloon);
    //     }
    // }

    // ----------------- balloons end -----------------

    componentDidMount() {


        fetch(API_ENDPOINT +  '/slides', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            }
        }).then((res) => res.json()).then((result) => {
            this.setState({
                slides: result
            })
            console.log(result);
        })

        fetch(API_ENDPOINT + '/home', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            }
        }).then((res) => res.json()).then((result) => {

            console.log(result);

            this.setState({
                home: result
            })
        })

               
        // // balloons
        // this.balloonContainer = document.getElementById("balloon-container");
        //
        // window.addEventListener("click", () => {
        //     this.removeBalloons();
        // });
        //
        // this.createBalloon(20)
        //
        // setTimeout(() => {
        //     this.createBalloon(20)
        // }, [3000])
        //
        // setTimeout(() => {
        //     this.createBalloon(20)
        // }, [5000])

    }

    componentWillUnmount() {
        // window.removeEventListener('click', this.removeBalloons, false);
    }

    // balloons end

    render() {
        const home = true;
        return (
            <div>
                <Karousel onScroll={() => {
                    window.scrollTo({
                        top: this.firstNode.getBoundingClientRect().top,
                        behavior: 'smooth'
                    })
                }} />
                <div ref={(node) => this.firstNode = node}>
                    <AboutSection content={this.state.home ? this.state.home.section[0] : {}} />
                </div>
                <ServicesSection items={this.state.home ? this.state.home.webservice : []} />
                <GoogleMapSection {...this.props} />
                <ShipingSafetySection content={this.state.home ? this.state.home.section[1] : {}} />
                <News />
                <Container className="shippingSafetySection">
                    <Row>
                        <Col xl="5" lg="5" md="6" xs="7">
                            <h3 className="universalh3"><span style={{ color: '#DA212E' }}>Ponuda</span> vozila</h3>
                            <Row>
                                <Col xl="10" className="no-margin">
                                    <p className="universalParagraph">Kompletnu ponudu polovnih teretnih vozila možete pogledati na našem OLX Shop-u</p>

                                    <a href="https://euroexpress.olx.ba/"><AnimtedButton buttonText={"POGLEDAJTE KOMPLETNU PONUDU"} newButtonDesign={true} /></a>

                                </Col>
                            </Row>
                        </Col>
                        <Col xl="6" lg="6" md="6" xs="5" className="offset-xl-1 offet-lg-1">
                            <img src={cars} className="img-fluid" />
                        </Col>
                    </Row>
                </Container>

                {/*<EducationSection content={this.state.home ? this.state.home.section[2] : {}}/>*/}
                
                {/* Balloon container */}
                {/*<div id='balloon-container'>*/}

                {/*</div>*/}
            </div>
        )

    }

}

export default PageWithLayout(Home) 